const colors = {
  yellow: "#FAED27",

  white: "#ffffff",
  black: "#000",

  lightGray: "#ddd",
  regularGray: "#ccc",
  darkGray: "#989898",

  fontGray: "#333",
};

export default colors;
