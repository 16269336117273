// mobile first design --> 320px - 374px width as a base (iPhone 5, Galaxy S5, Moto G4)

const size = {
  xs: "375px", //iPhone6, X
  sm: "480px", //Windows Phone, Nexus Tablet
  md: "768px", //iPad, tablets
  lg: "1024px", //iPad Pro
  xl: "1200px", //laptop
  xxl: "2000px", //big desktop
};

const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
};

export { device };

// @media only screen and ${device.xs} {
//
// }

// @media only screen and ${device.sm} {
//
// }

// @media only screen and ${device.md} {
//
// }

// @media only screen and ${device.lg} {
//
// }

// @media only screen and ${device.xl} {
//
// }

// @media only screen and ${device.xxl} {
//
// }
