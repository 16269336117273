import { createGlobalStyle } from "styled-components";
import fonts from "./fonts";
import colors from "./colors";
import { device } from "./breakpoints";

const GlobalStyle = createGlobalStyle`
html {
  font-size: 50%; 

  @media only screen and ${device.md} {
    font-size: 56.25%
  }

  @media only screen and ${device.xl} {
    font-size: 62.5%
  }

  @media only screen and ${device.xxl} {
    font-size: 75%;
  }
}

body {
    box-sizing: border-box;
    background-color: ${colors.lightGray};
    color: ${colors.fontGray};
    ${fonts.bodyRegular};
};

  :root {
    --swiper-theme-color: ${colors.yellow};
  }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
};

p {
  font-size: 1.6rem;
  line-height: 1.5;
};

::-webkit-scrollbar {
  width: 0.25rem;
  margin: 0 0.25rem;
  &-thumb {
    background: ${colors.yellow};
    border-radius: 1rem;
    &:hover {
      background: ${colors.yellow};
    }
  }
}

`;

export default GlobalStyle;
