import Head from "next/head";
import { Toaster } from "react-hot-toast";

import GlobalStyle from "../util/globalStyles";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Barbara Osowski | REALTOR</title>
        <meta
          name="description"
          content="Barbara Osowski, realtor in Colorado Springs, CO."
        />
        <meta
          name="keywords"
          content="barbhasthekey, realtor, colorado springs, real estate, colorado, barbara osowski, osowski"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/icons/favicon-16x16.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-touch-icon.png"
        />
        <link rel="manifest" href="/icons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/icons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
      </Head>
      <GlobalStyle />
      <Toaster />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
